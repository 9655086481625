.logo img {
  height: 55px;
  margin: 22px;
}

.siteLayout .siteLayoutBackground {
  background: #fff;
}

[data-theme="dark"] .siteLayout .siteLayoutBackground {
  background: #141414;
}