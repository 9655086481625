.main {
  input {
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .button {
    margin-top: 10px;
    background-color: #4c5254;
    color: white !important;
    transition: 1s;

    &:hover {
      background-color: #787f83;
      transition: 0.5s;
    }
  }

  textarea {
    width: 400px;
    text-align: center;
  }
}

.category-prices {
  * {
    margin-top: 5px;
  }
  margin: 20px;
}
