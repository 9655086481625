.main {
  .forms {
    padding: 10px;
    display: flex;
    justify-content: center;

    .image {
      width: min-content;
      padding: 10px;
      font-size: 20px;
      background-color: #769676;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      transition: 1s;

      &:hover {
        background-color: #566c56;
        transition: 0.5s;
      }
    }
  }

  .button {
    margin-top: 10px;
    background-color: #4c5254;
    color: white !important;
    transition: 1s;

    &:hover {
      background-color: #787f83;
      transition: 0.5s;
    }
  }

  .span {
    display: none;
  }

  .viewLink {
    background-color: #1890ff;
    color: #fff;
    padding: 4px 15px;
  }
}

.pricesWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 15px;
}
