@import '~antd/dist/antd.min.css';

.App {
  text-align: center;
}

.ant-breadcrumb a,
.breadcrumb-item a,
.ant-breadcrumb-link,
.breadcrumb-item.active {
  font-size: 18px;
}

.ant-tabs-tabpane {
  flex: none;
  /* display: flex; */
  justify-content: center;
  width: 100%;
  outline: none;
}

.product-page {
  display: block !important;
}

.product-page .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}
