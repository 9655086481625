.dates {
    display: flex;
    flex-wrap: nowrap;
    gap:15px;
    justify-content: center;
}
.checkbox {
    margin-left: 15px;
}

.actions {
    display: flex;
    gap: 10px;
}