.ceil {
  max-width: 350px;
  text-overflow: ellipsis;
  max-height: 100px;
  overflow: hidden;
  &:active {
    max-height: 100%;
    overflow: unset;
  }
}
