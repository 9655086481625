.main {
  ::placeholder {
    text-align: center;
  }

  input[type='number'] {
    -moz-appearance: textfield; /*For FireFox*/

    &::-webkit-inner-spin-button { /*For Webkits like Chrome and Safari*/
      -webkit-appearance: none;
      margin: 0;
    }
  }
}