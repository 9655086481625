.main {

  .inputField {
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px;
  }

  input {
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea {
    width: 400px;
    text-align: center;
  }
}