.manualRequests {
	width: 100%;
	display: flex;
	flex-direction: column;

	h4 {
		font-size: 18px;
		text-align: left;
		margin-bottom: 20px;
	}

	button {
		width: 100%;
		max-width: 300px;
		font-size: 16px;
		height: 40px;
		margin-bottom: 40px;
	}

	.attributes {
		display: flex;
		flex-direction: column;
		max-width: 300px;
		align-items: start;
		margin-bottom: 20px;
	}
}